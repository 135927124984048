import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";

import
  {
    PENDING,
    REJECTED,
    STATUS_OPTIONS,
    SUBMITTED,
  } from "@equidefi/shared/constants/accreditation";
import { capitalizeEveryWord } from "@equidefi/shared/helpers/string";

import moment from "moment/moment";
import Spinner from "../../components/spinner";
import { useAccreditationDocuments } from "../../hooks/useAccreditation";
import { useUserOfferings } from "../../hooks/useOfferings";
import { useRequireUser } from "../../hooks/useRequireUser";
import Header from "../../layouts/header";
import "./style.css";

const defaultValue = {
  label: capitalizeEveryWord(SUBMITTED),
  value: SUBMITTED,
};

const Row = ({ investor }) => {
  const navigate = useNavigate();

  if (investor.status === PENDING) {
    return (
      <tr className="disabled">
        <td className="name">
          {investor.user.first_name} {investor.user.last_name}
        </td>
        <td>
          {investor.investment.broker?.company
            ? `${investor.investment.broker?.company} - ${investor.investment.broker?.first_name} ${investor.investment.broker?.last_name}`
            : ""}
        </td>
        <td>{investor.accreditation_method?.replace("_", " ")}</td>
        <td>{moment(investor.create_date).format("ll")}</td>
        <td>{moment(investor.modify_date).format("ll")}</td>
        <td>{investor.status}</td>
      </tr>
    );
  }

  if (investor.status === REJECTED) {
    return (
      <tr
        onClick={() => navigate(`/accreditation/${investor.id}`)}
      >
        <td className="name">
          {investor.user.first_name} {investor.user.last_name}
        </td>
        <td>
          {investor.investment.broker?.company
            ? `${investor.investment.broker?.company} - ${investor.investment.broker?.first_name} ${investor.investment.broker?.last_name}`
            : ""}
        </td>
        <td>{investor.accreditation_method?.replace("_", " ")}</td>
        <td className="reason">{investor.rejection_reason}</td>
        <td>{moment(investor.create_date).format("ll")}</td>
        <td>{moment(investor.modify_date).format("ll")}</td>
        <td>{investor.status}</td>
      </tr>
    );
  }

  return (
    <tr onClick={() => navigate(`/accreditation/${investor.id}`)}>
      <td className="name">
        {investor.user.first_name} {investor.user.last_name}
      </td>
      <td>
        {investor.investment.broker?.company
          ? `${investor.investment.broker?.company} - ${investor.investment.broker?.first_name} ${investor.investment.broker?.last_name}`
          : ""}
      </td>
      <td>{investor.accreditation_method?.replace("_", " ")}</td>
      <td>{moment(investor.create_date).format("ll")}</td>
      <td>{moment(investor.modify_date).format("ll")}</td>
      <td>{investor.status}</td>
    </tr>
  );
};

const AccreditationList = () => {
  useRequireUser();

  const [status, setStatus] = useState(defaultValue);
  const { data: offerings, isLoading: offeringsLoading } = useUserOfferings();
  const [selectedOffering, setOffering] = useState();
  const accreditation = useAccreditationDocuments(
    selectedOffering?.value ?? offerings?.[0]?.id,
    status.value
  );
  useEffect(() => {
    accreditation.refetch().then();
  }, [accreditation.refetch, status, offerings, offeringsLoading]);

  if (
    accreditation.isLoading ||
    accreditation.isRefetching ||
    offeringsLoading
  ) {
    return <Spinner show={true} />;
  }

  return (
    <>
      <Header name="Review Accreditation Documents" />
      <br />
      <div className="container-fluid accreditation-table">
        <div className="form-floating mb-5 filter">
          <div className="row">
            <div className="col-6">
              <div className="col-10 label">Select an Offering:</div>
              <div className="col-10">
                <Select
                  autoFocus={false}
                  filterOption={null}
                  onChange={(target) => setOffering(target)}
                  value={selectedOffering}
                  isLoading={accreditation.isLoading}
                  defaultValue={{
                    label: `${offerings?.[0]?.issuer?.name} ${offerings?.[0]?.name}`,
                    value: offerings?.[0]?.id,
                  }}
                  options={offerings?.map((value) => {
                    return {
                      label: `${value.issuer.name} - ${value.name}`,
                      value: value.id,
                    };
                  })}
                  components={{
                    IndicatorSeparator: null,
                  }}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="col-8 label">Filter by status:</div>
              <div className="col-8">
                <Select
                  autoFocus={false}
                  filterOption={null}
                  onChange={(target) => setStatus(target)}
                  value={status}
                  isLoading={accreditation.isLoading}
                  options={STATUS_OPTIONS}
                  components={{
                    IndicatorSeparator: null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="table-responsive">
            <table className="table card-table table-hover mb-0">
              <thead>
                <tr>
                  <th>Investor</th>
                  <th>Broker</th>
                  <th>Accreditation Type</th>
                  {status.value === REJECTED ? <th>Reason</th> : null}
                  <th>Created</th>
                  <th>Modified</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {accreditation.data?.map((i) => (
                  <Row investor={i} key={i.id} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccreditationList;
