import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { mode } from '@chakra-ui/theme-tools' // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define a custom variant
const equidefiVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props // extract colorScheme from component props

  return {
    tab: {
      _selected: {
        color: mode(`${c}.500`, `${c}.300`)(props),
        borderColor: 'inherit',
        borderBottom: '2px solid',
        mb: '-2px',
      },
      _active: {
        borderColor: 'inherit',
        borderBottom: '2px solid',
      },
    },
    tablist: {
      borderBottom: 'none',
    }
  }
})

const variants = {
  equidefi: equidefiVariant,
}

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants })

