/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useParams } from "react-router";

import "react-datepicker/dist/react-datepicker.css";

import WizardAccount from "./account";
import WizardAgreements from "./agreements";
import WizardCalendar from "./calendar";
import WizardDocuments from "./documents";
import WizardExemptions from "./exemptions";
import WizardNav from "./nav";
import WizardOffering from "./offering";
import WizardProfile from "./profile";
import WizardSubscription from "./subscription";

import Header from "../../../layouts/header";

import { Box, Flex } from "@chakra-ui/react";
import { Paper } from "@equidefi/ui";
import { getSettingById, getSettingsByOfferingId } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";
import WizardBroker from "./brokers";
import WizardKyc from "./kyc";

const Wizard = (props) => {
  const dispatch = useDispatch();

  const { id, section } = useParams();
  const { data: offering } = useOffering(id);

  useEffect(() => {
    (async () => {
      if (!offering) return;

      const settings = await getSettingsByOfferingId(id);
      offering.settings = {};

      const paymentBankType = getSettingById(settings, "payment_bank_type");

      offering.has_escrow = paymentBankType
        ? paymentBankType === "escrow" || paymentBankType === "escrow est"
          ? "Yes"
          : "No"
        : "";
      offering.has_equityStock = paymentBankType
        ? paymentBankType === "escrow est"
          ? "Yes"
          : "No"
        : "";
      offering.need_escrow_agent =
        paymentBankType === "Need Escrow Agent" ? "Yes" : "No";
      offering.payment_bank_type = getSettingById(
        settings,
        "payment_bank_type"
      );
      offering.payment_bank_name = getSettingById(
        settings,
        "payment_bank_name"
      );
      offering.payment_account_name = getSettingById(
        settings,
        "payment_account_name"
      );
      offering.payment_account_number = getSettingById(
        settings,
        "payment_account_number"
      );
      offering.payment_account_number_confirm = getSettingById(
        settings,
        "payment_account_number"
      );
      offering.payment_routing_number = getSettingById(
        settings,
        "payment_routing_number"
      );
      offering.payment_routing_number_confirm = getSettingById(
        settings,
        "payment_routing_number"
      );
      offering.has_stock_ticker = offering.stock_ticker ? true : false;

      dispatch({ type: "offering", payload: { ...offering } });
      dispatch({ type: "settings", payload: settings });
    })();
  }, [section, offering]);

  return (
    <>
      <Header name={offering?.name} title={offering?.issuer?.name}></Header>

      <div className="container-fluid">
        <Flex direction="row">
          <Box>
            <WizardNav id={offering?.id} section={section} />
          </Box>

          <Paper flexGrow="1" p="5">
            <Routes>
              <Route path="account" element={<WizardAccount />} />
              <Route path="offering" element={<WizardOffering />} />
              <Route path="kyc" element={<WizardKyc />} />
              <Route path="profile" element={<WizardProfile />} />
              <Route path="subscription" element={<WizardSubscription />} />
              <Route path="exemptions" element={<WizardExemptions />} />
              <Route path="documents" element={<WizardDocuments />} />
              <Route path="brokers" element={<WizardBroker />} />
              <Route path="agreements" element={<WizardAgreements />} />
              <Route path="calendar" element={<WizardCalendar />} />
            </Routes>
          </Paper>
        </Flex>
      </div>
    </>
  );
};

export default Wizard;
