import { Button, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router";

import { useSendResetPasswordEmail } from "@equidefi/portals/hooks/useUsers";

import FormControl from "../../components/formControl";
import { forgotFormSchema } from "../../constants/forms";

const Forgot = (props) => {
  document.title = "Forgot Password | Powered by EquiDeFi";

  const formData = {
    email: "",
  };

  const navigate = useNavigate();
  const toast = useToast();
  const resetPassword = useSendResetPasswordEmail();

  const handleSubmit = async ({ email }, { resetForm }) => {
    try {
      await resetPassword.mutateAsync({ email, source: "issuer" });
      toast({
        description: `An email as been sent to ${email} with instructions to reset your password.`,
      });
      navigate("/");
    } catch (e) {
      toast({
        status: "error",
        description: e.response?.data?.errors?.join(". "),
      });
    } finally {
      resetForm();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="150px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="text-center mb-3">Forgot Password?</h1>
          <p className="text-muted text-center mb-5">
            Enter your email to get a password reset link.
          </p>

          <Formik
            initialValues={formData}
            validationSchema={forgotFormSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
              handleBlur,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="email"
                  name="email"
                  title="Email Address"
                  autoFocus
                  required={true}
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange}
                />

                <Button
                  isLoading={resetPassword.isLoading}
                  isDisabled={values.email ? !isValid : true}
                  type="submit"
                  w="full"
                >
                  Continue
                </Button>

                <div className="text-center mt-3">
                  <small className="text-muted text-center">
                    Remember your password? <Link to="/">Log in</Link>.
                  </small>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(/img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
