import {
  StyleConfig,
  defineStyle,
  extendTheme,
  withDefaultColorScheme,
  type ColorMode,
  type ThemeConfig,
} from "@chakra-ui/react";
import { PaperTheme } from "../Paper/PaperTheme";
import { tabsTheme } from "../Tabs/TabsTheme";
import { textStyles } from "./text-styles";

export const EquidefiTheme = extendTheme(
  {
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
      disableTransitionOnChange: true,
    } as ThemeConfig,
    styles: {
      global: ({ colorMode }: { colorMode: ColorMode }) => {
        return {
          "html, body": {
            backgroundColor: colorMode === "dark" ? "gray.900" : "#f9fbfd",
          },
        };
      },
    } as StyleConfig,
    textStyles,
    colors: {
      secondary: "#7283a0",
      primary: "#172a4c",
      accent: "#7147bd",
      background: "#f0f5fa",
      equidefi: {
        darkGray: "#7283a0",
        red: "#ca0000",
        green: "#7eaa6d",
        yellow: "#f5ac4c",
        blue: "#3959de",
      },
      purple: {
        50: "#f1ecf9",
        100: "#d8c9ee",
        200: "#bea6e3",
        300: "#a583d8",
        400: "#8c60cd",
        500: "#723dc2",
        600: "#5c319b",
        700: "#452574",
        800: "#2e194d",
        900: "#170c27",
      },
    },
    components: {
      Progress: {
        variants: {
          filled: {
            filledTrack: {
              backgroundColor: "#ffffff",
              background:
                "linear-gradient(rgba(132, 132, 132, 0.4), rgba(219, 219, 219, 0.4))",
            },
            track: {
              zIndex: 10,
              height: "10px",
              borderRadius: "5px",
            },
          },
          unfilled: {
            track: {
              height: "3.33px",
              backgroundColor: "#ffffff",
              opacity: "50%",
              borderRightRadius: "5px",
            },
          },
        },
      },
      Accordion: {
        variants: {
          card: defineStyle({
            container: {
              borderRadius: "lg",
              background: "white",
              boxShadow: "card",
              my: 1,
            },
            button: {
              _hover: { bg: "", filter: "brightness(90%)" },
              py: 2,
              px: 4,
              accentColor: "#3959DE",
            },
          }),
        },
      },
      Table: {
        baseStyle: {
          td: defineStyle({
            verticalAlign: "baseline",
          }),
        },
      },
      FormLabel: {
        baseStyle: {
          fontSize: "base",
          fontWeight: "normal",
        },
      },
      Paper: PaperTheme,
      Input: {
        baseStyle: {
          field: {
            bg: "white",
            background: "white",
          },
        },
      },
      Textarea: {
        baseStyle: defineStyle({
          bg: "white",
          background: "white",
        }),
      },
      Tabs: tabsTheme,
      Button: {
        baseStyle: defineStyle({
          fontWeight: "normal",
        }),
        variants: {
          secondary: defineStyle({
            backgroundColor: "#7283a0",
            _hover: {
              filter: "brightness(90%)",
            },
            _active: {
              filter: "brightness(50%)",
            },
            color: "white",
            transition: "0.1s",
          }),
        },
      },
    },
    shadows: {
      paper: "0px 0px 30px 0px rgba(0, 0, 0, 0.10)",
      card: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    },
    fonts: {
      figtree: `"Figtree", sans-serif`,
      heading: `"Cerebri Sans", -apple-system, "system-ui", Helvetica, Arial, sans-serif`,
      body: `"Cerebri Sans", -apple-system, "system-ui", Helvetica, Arial, sans-serif`,
    },
  },
  withDefaultColorScheme({ colorScheme: "purple" })
);
