import
  {
    Badge,
    Button,
    ButtonGroup,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
import moment from "moment";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import swal from "sweetalert";

import
  {
    useCreateOfferingAgreement,
    useDeleteOfferingAgreement,
    useOfferingAgreements,
    useUpdateOfferingAgreement,
  } from "@equidefi/portals/hooks/useAgreements";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Paper, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import { OfferingAgreementModal } from "@/components/offerings/OfferingAgreementModal";
import { isAdmin } from "@/helpers";
import { useOffering } from "@/hooks/useOfferings";
import Header from "@/layouts/header";

const OfferingAgreementRow = ({
  canEdit = false,
  agreement,
  onEdit = () => {},
  onDelete = () => {},
}) => {
  return (
    <Tr>
      <Td>
        <Text mb="1">{agreement.name}</Text>
        <Text fontSize="sm" mb="0" color="gray.500" size="sm">
          Template ID: {agreement.template_id}
        </Text>
      </Td>
      <Td>
        {agreement.create_date && moment(agreement.create_date).format("ll")}
      </Td>
      <Td>
        <Badge colorScheme="gray">{agreement.entity_type}</Badge>
      </Td>
      <Td textAlign="end">
        <ButtonGroup size="sm" colorScheme="gray">
          {canEdit && (
            <IconButton
              icon={<Icon.Edit size="1em" />}
              aria-label="Edit Agreement"
              onClick={() => onEdit(agreement)}
            />
          )}
          {canEdit && (
            <IconButton
              icon={<Icon.Trash size="1em" />}
              aria-label="Delete Agreement"
              onClick={() => onDelete(agreement)}
            />
          )}
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const OfferingAgreements = (props) => {
  const { id } = useParams();
  const toast = useToast();

  const createModal = useDisclosure(false);
  const [, showSpinner] = useState(false);

  const [agreement, setAgreement] = useState(null);

  const { data: offering, refetch: refetchOffering } = useOffering(id);
  const {
    data: offeringAgreements,
    refetch: refetchAgreements,
    isSuccess,
  } = useOfferingAgreements(id);
  const deleteOfferingAgreement = useDeleteOfferingAgreement(id);
  const createOfferingAgreement = useCreateOfferingAgreement(id);
  const updateOfferingAgreement = useUpdateOfferingAgreement(id, agreement?.id);

  const { data: user } = useCurrentUser();

  const handleCreate = async (data) => {
    try {
      const offeringAgreement = await createOfferingAgreement.mutateAsync(data);

      toast({
        status: "success",
        description: `${offeringAgreement.name} has been created`,
      });
      createModal.onClose();
    } catch (e) {
      toast({
        status: "error",
        description: "We could not create this offering agreement",
      });
    } finally {
      refetchOffering();
      refetchAgreements();
    }
  };

  const handleUpdate = async (data) => {
    try {
      const offeringAgreement = await updateOfferingAgreement.mutateAsync(data);

      toast({
        status: "success",
        description: `${offeringAgreement.name} has been updated`,
      });

      setAgreement(null);
    } catch (e) {
      toast({
        status: "error",
        description: "We could not update this offering agreement",
      });
    } finally {
      refetchOffering();
      refetchAgreements();
    }
  };

  const handleDelete = async (agreement) => {
    try {
      const status = await swal({
        title: `Remove Offering Agreement`,
        text: `Are you sure you want to remove '${agreement.name}'?`,
        buttons: ["Cancel", "Yes"],
        icon: "error",
        dangerMode: true,
      });

      if (!status) return;

      showSpinner(true);
      await deleteOfferingAgreement.mutateAsync(agreement.id);

      toast({
        status: "success",
        description: `${agreement.name} has been removed`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${agreement.name} could not be removed`,
      });
    } finally {
      showSpinner(false);
      refetchOffering();
      refetchAgreements();
    }
  };

  const canEdit = useMemo(() => isAdmin(user), [user]);

  const anyAgreements = offeringAgreements?.length > 0;

  return (
    <>
      <Header
        id={id}
        name="Offering Agreements"
        iconName="fe fe-info ms-2"
        title={offering?.name}
      >
        {canEdit && offeringAgreements?.length < 2 && (
          <Button
            leftIcon={<Icon.Plus size="1em" />}
            size="sm"
            {...createModal.getButtonProps()}
          >
            Add Agreement
          </Button>
        )}
      </Header>

      <OfferingAgreementModal
        isOpen={createModal.isOpen}
        isLoading={createOfferingAgreement.isLoading}
        onClose={createModal.onClose}
        onComplete={handleCreate}
      />

      <OfferingAgreementModal
        agreement={agreement}
        isOpen={!!agreement}
        isLoading={updateOfferingAgreement.isLoading}
        onClose={() => setAgreement(null)}
        onComplete={handleUpdate}
      />

      {!anyAgreements && (
        <div className="container-fluid">
          <Paper p="4">
            <Text
              mb="0"
              py="5"
              textAlign="center"
              fontWeight="bold"
              fontSize="lg"
              color="gray.400"
            >
              {isSuccess
                ? "No offering agreements"
                : "Loading offering agreements..."}
            </Text>
          </Paper>
        </div>
      )}

      {anyAgreements && (
        <div className="container-fluid">
          <Paper p="0">
            <TableContainer>
              <Table size="lg" variant="simple" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Date</Th>
                    <Th>Entity Type</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {offeringAgreements?.map((agreement) => (
                    <OfferingAgreementRow
                      key={agreement.id}
                      agreement={agreement}
                      onDelete={(agreement) => handleDelete(agreement)}
                      onEdit={(agreement) => setAgreement(agreement)}
                      canEdit={canEdit}
                    />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  );
};

export default OfferingAgreements;
