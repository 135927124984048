import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
interface RouteProps extends PropsWithChildren {
  fallbackUrl: string;
}

export const PrivateRoute: FC<RouteProps> = ({ children, fallbackUrl }) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn && fallbackUrl) return <Navigate to={fallbackUrl} />;

  return <>{children}</>;
};

export const PublicRoute: FC<RouteProps> = ({ children, fallbackUrl }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn && fallbackUrl) return <Navigate to={fallbackUrl} />;

  return <>{children}</>;
};
