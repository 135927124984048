import React from "react";
import { Outlet } from "react-router";


import { Box } from "@chakra-ui/react";
import { useRequireUser } from "../hooks/useRequireUser";
import Menu from "./menu";

export const AdminLayout = () => {
  useRequireUser();

  return (
    <>
      <Menu />
      <Box pb={3} className="main-content">
        <Outlet />
      </Box>
    </>
  );
};
