
import { useQuery } from "@tanstack/react-query";
import { useApi } from "./useApi";
import IssuerClient from "../clients/IssuerClient";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuer = (id: string, options = {}) => {
  const api = useApi(IssuerClient);

  return useQuery(["issuers", id], () => api.getIssuer(id), {
    enabled: !!id,
    ...options,
  });
};

/**
 * @param {string} search
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuers = (search: string, options = {}) => {
  const api = useApi(IssuerClient);

  return useQuery(["issuers", search], () => api.listIssuers(search), options);
};
