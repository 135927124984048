import { Stack, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import { Link, useNavigate, useParams } from "react-router";
import * as Yup from "yup";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { Input } from "@equidefi/ui";
import Spinner from "../../../components/spinner";
import { useOffering } from "../../../hooks/useOfferings";

Yup.addMethod(Yup.string, "stripEmptyString", function () {
  return this.transform((value) =>
    typeof value === "string" && value.trim() === "" ? null : value
  );
});

const KycOfferingSchema = Yup.object().shape({
  us_kyc_template_id: Yup.string().stripEmptyString().nullable(),
  us_kyb_template_id: Yup.string().stripEmptyString().nullable(),
  intl_kyc_template_id: Yup.string().stripEmptyString().nullable(),
  intl_kyb_template_id: Yup.string().stripEmptyString().nullable(),
});

const WizardKyc = () => {
  const params = useParams();
  const { data: offering } = useOffering(params.id);
  const navigate = useNavigate();
  const [spinner, showSpinner] = useState(false);
  const offeringApi = useApi(OfferingClient);
  const toast = useToast();
  const handleUpdateKYC = useCallback(
    async (data) => {
      const result = KycOfferingSchema.cast(data);
      showSpinner(true);

      try {
        await offeringApi.update(offering.id, result);
        navigate(`/offering/${offering.id}/wizard/brokers`);
      } catch (e) {
        toast({
          status: "error",
          description: "Something went wrong. Our team has been notified.",
        });
      } finally {
        showSpinner(false);
      }
    },
    [navigate, offering?.id]
  );

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Persona KYC/KYB Templates</h2>

      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            us_kyc_template_id: offering?.us_kyc_template_id ?? null,
            us_kyb_template_id: offering?.us_kyb_template_id ?? null,
            intl_kyc_template_id: offering?.intl_kyc_template_id ?? null,
            intl_kyb_template_id: offering?.intl_kyb_template_id ?? null,
          }}
          validationSchema={KycOfferingSchema}
          onSubmit={handleUpdateKYC}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            getFieldProps,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <hr />
                <h3>USA Investor KYC Templates</h3>

                <Stack gap={{ lg: 5 }} flexDir={{ base: "column", lg: "row" }}>
                  <Input
                    name="us_kyc_template_id"
                    label="KYC Template ID for US Investors"
                    error={errors.us_kyc_template_id}
                    isInvalid={
                      errors.us_kyc_template_id && touched.us_kyc_template_id
                    }
                    {...getFieldProps("us_kyc_template_id")}
                  />

                  <Input
                    name="us_kyb_template_id"
                    label="KYB Template ID for US Investors"
                    error={errors.us_kyb_template_id}
                    isInvalid={
                      errors.us_kyb_template_id && touched.us_kyb_template_id
                    }
                    {...getFieldProps("us_kyb_template_id")}
                  />
                </Stack>

                <hr />

                <h3>International Investor KYC Templates</h3>

                <Stack gap={{ lg: 5 }} flexDir={{ base: "column", lg: "row" }}>
                  <Input
                    name="intl_kyc_template_id"
                    label="KYC Template ID for International Investors"
                    error={errors.intl_kyc_template_id}
                    isInvalid={
                      errors.intl_kyc_template_id &&
                      touched.intl_kyc_template_id
                    }
                    {...getFieldProps("intl_kyc_template_id")}
                  />
                  <Input
                    name="intl_kyb_template_id"
                    label="KYB Template ID for International Investors"
                    error={errors.intl_kyb_template_id}
                    isInvalid={
                      errors.intl_kyb_template_id &&
                      touched.intl_kyb_template_id
                    }
                    {...getFieldProps("intl_kyb_template_id")}
                  />
                </Stack>

                <hr />

                <div className="d-flex flex-row justify-content-start">
                  <button className="btn btn-eq-primary" type="submit">
                    Save &amp; Continue
                  </button>
                  <Link
                    to={`/offering/${params.id}/wizard/brokers`}
                    className="btn btn-link"
                    type="submit"
                  >
                    Skip
                  </Link>
                </div>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardKyc;
