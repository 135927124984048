import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { CurrencyFormatter } from "../../../constants/format";
import { IconControl } from "../../../helpers";
import {
    useOfferingFull,
    useOfferingUpdate,
} from "../../../hooks/useOfferings";
import { isUnitOffering } from "../helpers";

const WizardSubscription = (props) => {
  const navigate = useNavigate();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data, refetch } = useOfferingFull(id);
  const { offering, summary } = data ?? {};

  const { mutateAsync: updateOffering } = useOfferingUpdate(id);

  const handleOnSubmit = async (data) => {
    showSpinner(true);

    await updateOffering({
      investor_minimum_units: data.investor_minimum_units,
    });
    refetch();

    showSpinner(false);
    navigate(`/offering/${offering.id}/wizard/exemptions`);
  };

  const formSchema = Yup.object().shape({
    investor_minimum_amount: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined || value === 0
          ? null
          : value
      )
      .nullable()
      .min(
        0,
        `Minimum amount should be more than or equal to ${CurrencyFormatter.format(
          offering?.unit_price
        )}`
      ),
    investor_minimum_units: Yup.string()
      .nullable(true)
      .when("stock_type", (data) => {
        return Yup.number()
          .test(
            "invalid decimal",
            "Value should not be in decimal format",
            (value) => !(value + "").match(/^\d*\.{1}\d*$/)
          )
          .typeError("");
      }),
  });
  const formSchemaConvertibleNote = Yup.object().shape({
    investor_minimum_amount: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined || value === 0
          ? null
          : value
      )
      .min(
        summary?.investor_minimum_amount == null
          ? null
          : Number(offering?.unit_price),
        `Minimum amount should be more than or equal to ${CurrencyFormatter.format(
          offering?.unit_price
        )}`
      )
      .max(
        summary?.minimum_offering_amount,
        `Minimum amount should be less than ${CurrencyFormatter.format(
          summary?.minimum_offering_amount
        )}`
      )
      .nullable(true),
  });

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Would you like to define subscription limitations?</h2>
      <hr />

      <Spinner show={spinner}>
        <Formik
          enableReinitialize
          initialValues={{
            investor_minimum_amount: summary?.investor_minimum_amount,
            investor_minimum_units: Math.round(
              summary?.investor_minimum_amount / Number(offering.unit_price)
            ),
          }}
          validationSchema={
            offering?.stock_type !== "convertible_note"
              ? formSchema
              : formSchemaConvertibleNote
          }
          onSubmit={handleOnSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, values, errors, touched, getFieldProps }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <IconControl
                    placement="right"
                    iconName="fe fe-info ms-2"
                    inputTitle="Minimum Subscription Amount"
                    tooltipTitle="This is the minimum subscription amount you are willing to accept from a subscriber"
                  />
                  <FormControl
                    type="money"
                    decimalScale={2}
                    fixedDecimalScale={2}
                    error={
                      touched.investor_minimum_amount &&
                      errors.investor_minimum_amount
                    }
                    {...getFieldProps("investor_minimum_amount")}
                  />
                </div>
                {offering?.stock_type !== "convertible_note" &&
                  !offering?.stock_type?.split(" ").includes("Safe") && (
                    <div className="col">
                      <FormControl
                        type="number"
                        title={
                          isUnitOffering(offering)
                            ? "Minimum Units"
                            : "Minimum Shares"
                        }
                        disabled
                        {...getFieldProps("investor_minimum_units")}
                        value={Math.round(
                          values?.investor_minimum_amount /
                            Number(offering.unit_price)
                        )}
                        error={errors.investor_minimum_units}
                      />
                    </div>
                  )}
              </div>
              <hr />
              <button className="btn btn-eq-primary" type="submit">
                Save &amp; Continue
              </button>
            </form>
          )}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardSubscription;
