import { ApiClient } from "@equidefi/portals/clients";
import { ApiInstance } from "./api";

export default class StripeClient extends ApiClient {
  static async createSetupIntent(issuerId) {
    const response = await ApiInstance.post("/v2/stripe/setup-intent/", {
      issuer_id: issuerId,
    });
    return response.data;
  }

  static async getSetupIntent(issuerId) {
    const response = await ApiInstance.get(
      `/v2/stripe/setup-intent/${issuerId}`
    );
    return response;
  }

  static async savePaymentMethod(issuerId, setupIntentId) {
    const response = await ApiInstance.post("/v2/stripe/payment-method/", {
      issuer_id: issuerId,
      setup_intent_id: setupIntentId,
    });
    return response.data;
  }

  static async getPaymentMethod(issuerId) {
    const response = await ApiInstance.get(
      `/v2/stripe/payment-method/${issuerId}`
    );
    return response.data;
  }

  async confirmPayment(investmentId, paymentId) {
    const response = await this.post(
      `/v2/stripe/payment-intent/capture/${investmentId}/${paymentId}`
    );
    return response.data;
  }

  async terminatePayment(investmentId, paymentId) {
    const response = await this.delete(
      `/v2/stripe/payment-intent/terminate/${investmentId}/${paymentId}`
    );
    return response.data;
  }

  async getPaymentMethodDomain(offeringId) {
    const response = await this.get(
      `/v2/stripe/payment-method-domain/${offeringId}`
    );
    return response.data;
  }

  async createPaymentMethodDomain(offeringId, enabled) {
    const response = await this.post(
      `/v2/stripe/payment-method-domain/${offeringId}`,
      { enabled }
    );
    return response.data;
  }
}
