import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const MissingPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    toast({
      status: "error",
      description: "That page does not exist",
    });
    navigate("/offering");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default MissingPage;
