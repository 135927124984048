import { 
  Tab as ChakraTab,
  TabProps as ChakraTabProps,
  Link,
  forwardRef
} from "@chakra-ui/react";

export interface TabProps extends Omit<ChakraTabProps, 'onClick'> {
  href?: string;
  onClick?: () => void;
}

export const Tab = forwardRef<TabProps, 'button'>((props, ref) => {
  const { href, onClick, children, ...rest } = props;

  if (href) {
    return (
      <Link href={href} _hover={{ textDecoration: 'none' }}>
        <ChakraTab
          ref={ref}
          onClick={(e) => {
            if (onClick) {
              e.preventDefault();
              onClick();
            }
          }}
          {...rest}
        >
          {children}
        </ChakraTab>
      </Link>
    );
  }

  return (
    <ChakraTab
      ref={ref}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ChakraTab>
  );
});

Tab.displayName = 'Tab';
