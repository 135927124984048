import axios from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import "react-datepicker/dist/react-datepicker.css";

import MaintenanceScreen from "@equidefi/portals/components/MaintenanceScreen";
import { initialize as initializeFullStory } from "@equidefi/portals/helpers/fullstory";
import
  {
    useCurrentUser,
    useIdentifyLDUser,
  } from "@equidefi/portals/hooks/useUsers";

import AppRoutes from "./Routes";
import { CONFIG } from "./constants/config";
import { isAdmin } from "./helpers";

initializeFullStory(CONFIG.FULLSTORY_ORG_ID);

const App = (props) => {
  const { data: me } = useCurrentUser();
  const admin = useMemo(() => isAdmin(me), [me]);

  useIdentifyLDUser(me);
  const flags = useFlags();

  axios.defaults.baseURL = CONFIG.EQ_API_URL;
  if (me?.token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${me?.token}`;
  }

  if (flags.maintenanceMode) return <MaintenanceScreen />;

  return <AppRoutes isAdmin={admin} />;
};

export default App;
