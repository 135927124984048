import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router";

import BgAuth from "@public/img/bg-auth.png";

export const AuthLayout = () => {
  return (
    <Box
      as="main"
      w="100vw"
      h="100vh"
      bgRepeat="no-repeat"
      bgPos="center"
      bgImage={BgAuth}
      p={{ base: "0", sm: "50px", md: "100px" }}
    >
      <Flex h="full" w="full">
        <Outlet />
      </Flex>
    </Box>
  );
};
