import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useIssuer } from "@equidefi/portals/hooks/useIssuers";
import BankingInfo from "../../components/invoices/BankingInfo";
import Invoices from "../../components/invoices/Invoices";
import { useInvoices } from "../../hooks/useInvoices";
import "./style.css";

const InvoiceList = () => {
  const { data: user } = useCurrentUser();
  const id = Object.keys(user?.roles?.issuer ?? {})?.[0];
  const { data: issuer } = useIssuer(id);

  const { data, isLoading } = useInvoices(issuer?.id);

  return (
    <>
      <div className="container-fluid">
        <BankingInfo />
        <Invoices invoices={data} isLoading={isLoading} />
      </div>
    </>
  );
};

export default InvoiceList;
