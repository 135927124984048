import { useMutation, useQuery } from "@tanstack/react-query";

import InvestmentClient from "@equidefi/portals/clients/InvestmentClient";
import PaymentClient from "@equidefi/portals/clients/PaymentClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

import IssuerInvestmentClient from "../clients/InvestmentClient";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestment = (id, options = {}) => {
  const investmentApi = useApi(InvestmentClient);

  return useQuery(["investment", id], () => investmentApi.get(id), {
    enabled: !!id,
    ...options,
  });
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentRefundPayment = (id, options = {}) => {
  const paymentsApi = useApi(PaymentClient);

  return useMutation(
    (data) => paymentsApi.refundManualPayemnt({ investment_id: id, ...data }),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentConfirmPayment = (id, options = {}) => {
  const paymentsApi = useApi(PaymentClient);

  return useMutation(
    (data) => paymentsApi.confirmManualPayment({ investment_id: id, ...data }),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentCancelPayment = (options = {}) => {
  const paymentsApi = useApi(PaymentClient);

  return useMutation(
    (data) => paymentsApi.cancelManualPayment(data),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentAddRefund = (id, options = {}) => {
  const paymentsApi = useApi(PaymentClient);

  return useMutation(
    ({ data }) => paymentsApi.addManualRefund({ investment_id: id, ...data }),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentAddManualPayment = (id, options = {}) => {
  const paymentsApi = useApi(PaymentClient);

  return useMutation(
    ({ data }) => paymentsApi.addManual({ investment_id: id, ...data }),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */

export const useInvestmentAgreementRecipients = (id, options = {}) => {
  const investmentApi = useApi(IssuerInvestmentClient);

  return useQuery(
    ["investment", id, "agreements"],
    () => investmentApi.getAgreements(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const usePokeInvestor = (id, options = {}) => {
  const investmentApi = useApi(InvestmentClient);

  return useMutation(async () => investmentApi.pokeInvestor(id), options);
};
