import React, { useCallback, useEffect, useState } from "react";

import { Formik } from "formik";

import { useNavigate, useParams } from "react-router";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { accountFormSchema } from "../../../constants/forms";
import { updateOffering, updateSettingById } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

const WizardAccount = () => {
  const params = useParams();
  const { data: offering } = useOffering(params.id);
  const navigate = useNavigate();
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    showSpinner(true);
    setTimeout(() => {
      showSpinner(false);
    }, 500);
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      showSpinner(true);
      if (data.has_escrow === "Yes" && data.has_equityStock === "Yes") {
        await updateSettingById(offering.id, "payment_bank_type", "escrow est");
        await updateSettingById(
          offering.id,
          "payment_bank_name",
          data["payment_bank_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_name",
          data["payment_account_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_number",
          data["payment_account_number"]
        );
        await updateSettingById(
          offering.id,
          "payment_routing_number",
          data["payment_routing_number"]
        );
      } else if (data.has_escrow === "Yes" && data.has_equityStock === "No") {
        await updateSettingById(offering.id, "payment_bank_type", "escrow");
        await updateSettingById(
          offering.id,
          "payment_bank_name",
          data["payment_bank_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_name",
          data["payment_account_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_number",
          data["payment_account_number"]
        );
        await updateSettingById(
          offering.id,
          "payment_routing_number",
          data["payment_routing_number"]
        );
      } else if (data.has_escrow === "No" && data.has_equityStock === "No") {
        await updateSettingById(offering.id, "payment_bank_type", "issuer");
        await updateSettingById(
          offering.id,
          "payment_bank_name",
          data["payment_bank_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_name",
          data["payment_account_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_number",
          data["payment_account_number"]
        );
        await updateSettingById(
          offering.id,
          "payment_routing_number",
          data["payment_routing_number"]
        );
      } else if (data.has_escrow === "No" && data.need_escrow_agent === "No") {
        await updateSettingById(offering.id, "payment_bank_type", "issuer");
        await updateSettingById(
          offering.id,
          "payment_bank_name",
          data["payment_bank_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_name",
          data["payment_account_name"]
        );
        await updateSettingById(
          offering.id,
          "payment_account_number",
          data["payment_account_number"]
        );
        await updateSettingById(
          offering.id,
          "payment_routing_number",
          data["payment_routing_number"]
        );
      } else {
        await updateSettingById(
          offering.id,
          "payment_bank_type",
          "Need Escrow Agent"
        );
        await updateSettingById(offering.id, "payment_bank_name", "");
        await updateSettingById(offering.id, "payment_account_name", "");
        await updateSettingById(offering.id, "payment_account_number", "");
        await updateSettingById(offering.id, "payment_routing_number", "");
      }

      updateOffering(offering.id, data).then((_) => {
        showSpinner(false);
        navigate(`/offering/${offering.id}/wizard/offering`);
      });
    },
    [navigate, offering?.id]
  );

  const resetBankDetails = (setFieldValue) => {
    setFieldValue("payment_bank_name", "");
    setFieldValue("payment_account_name", "");
    setFieldValue("payment_account_number", "");
    setFieldValue("payment_account_number_confirm", "");
    setFieldValue("payment_routing_number", "");
    setFieldValue("payment_routing_number_confirm", "");
  };

  const fillBankdetails = (setFieldValue) => {
    setFieldValue("payment_bank_name", "Capital One");
    setFieldValue("payment_account_name", "Equity Stock Transfer");
    setFieldValue("payment_account_number", "4670296606");
    setFieldValue("payment_account_number_confirm", "4670296606");
    setFieldValue("payment_routing_number", "065000090");
    setFieldValue("payment_routing_number_confirm", "065000090");
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Account Information</h2>
      <hr />
      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          validationSchema={accountFormSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values, errors, setFieldValue }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <div id="escrow">
                  <div className="d-flex justify-content-between mb-3">
                    <label>Do you have an escrow for investor funds?</label>
                    <div>
                      <label
                        className="form-check-label me-2"
                        htmlFor="has_escrow"
                      >
                        Yes
                      </label>
                      <input
                        className="form-check-input me-4"
                        label="Yes"
                        name="has_escrow"
                        type="radio"
                        value="Yes"
                        onChange={(e) => {
                          if (values.has_equityStock === "Yes") {
                            fillBankdetails(setFieldValue);
                          }
                          handleChange(e);
                        }}
                        checked={values?.has_escrow === "Yes"}
                      />

                      <label
                        className="form-check-label me-2"
                        htmlFor="has_escrow"
                      >
                        No
                      </label>
                      <input
                        className="form-check-input"
                        label="No"
                        name="has_escrow"
                        type="radio"
                        value="No"
                        onChange={(e) => {
                          resetBankDetails(setFieldValue);
                          handleChange(e);
                        }}
                        checked={values?.has_escrow === "No"}
                      />
                    </div>
                  </div>
                </div>

                {values?.has_escrow === "Yes" && (
                  <>
                    <hr />
                    <div className="d-flex justify-content-between mb-3">
                      <label>
                        Will you be using Equity Stock Transfer as your escrow
                        agent?
                      </label>
                      <div>
                        <label
                          className="form-check-label me-2"
                          htmlFor="has_equityStock"
                        >
                          Yes
                        </label>
                        <input
                          className="form-check-input me-4"
                          label="Yes"
                          name="has_equityStock"
                          type="radio"
                          value="Yes"
                          onChange={(e) => {
                            fillBankdetails(setFieldValue);
                            handleChange(e);
                          }}
                          checked={values.has_equityStock === "Yes"}
                        />

                        <label
                          className="form-check-label me-2"
                          htmlFor="has_equityStock"
                        >
                          No
                        </label>
                        <input
                          className="form-check-input"
                          label="No"
                          name="has_equityStock"
                          type="radio"
                          value="No"
                          onChange={(e) => {
                            resetBankDetails(setFieldValue);
                            handleChange(e);
                          }}
                          checked={values.has_equityStock === "No"}
                        />
                      </div>
                    </div>
                  </>
                )}

                {values?.has_escrow === "Yes" &&
                  values.has_equityStock === "Yes" && (
                    <>
                      <hr />
                      <h3>Escrow Bank Details</h3>
                      <FormControl
                        type="text"
                        name="payment_bank_name"
                        title="Bank name"
                        required={true}
                        placeholder="e.g. Bank of America"
                        value={values.payment_bank_name}
                        error={errors.payment_bank_name}
                        onChange={handleChange}
                        disabled
                        readOnly={true}
                      />

                      <FormControl
                        type="text"
                        name="payment_account_name"
                        title="Account name"
                        required={true}
                        placeholder="e.g. ABC Inc"
                        value={values.payment_account_name}
                        error={errors.payment_account_name}
                        onChange={handleChange}
                        disabled
                        readOnly={true}
                      />

                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_account_number"
                            title="Account Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_account_number}
                            error={errors.payment_account_number}
                            onChange={handleChange}
                            disabled
                            readOnly={true}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_account_number_confirm"
                            title="Confirm Account Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_account_number_confirm}
                            error={errors.payment_account_number_confirm}
                            onChange={handleChange}
                            disabled
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_routing_number"
                            title="ACH Routing Number"
                            required={true}
                            placeholder="e.g. 983721981"
                            value={values.payment_routing_number}
                            error={errors.payment_routing_number}
                            onChange={handleChange}
                            disabled
                            readOnly={true}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_routing_number_confirm"
                            title="Confirm ACH Routing Number"
                            required={true}
                            placeholder="e.g. 983721981"
                            value={values.payment_routing_number_confirm}
                            error={errors.payment_routing_number_confirm}
                            onChange={handleChange}
                            disabled
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </>
                  )}

                {values?.has_escrow === "Yes" &&
                  values.has_equityStock === "No" && (
                    <>
                      <h3 className="mt-3">Escrow Bank Details</h3>

                      <FormControl
                        type="text"
                        name="payment_bank_name"
                        title="Bank name"
                        required={true}
                        placeholder="e.g. Bank of America"
                        value={values.payment_bank_name}
                        error={errors.payment_bank_name}
                        onChange={handleChange}
                      />

                      <FormControl
                        type="text"
                        name="payment_account_name"
                        title="Account name"
                        required={true}
                        placeholder="e.g. ABC Inc"
                        value={values.payment_account_name}
                        error={errors.payment_account_name}
                        onChange={handleChange}
                      />

                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_account_number"
                            title="Account Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_account_number}
                            error={errors.payment_account_number}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_account_number_confirm"
                            title="Confirm Account Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_account_number_confirm}
                            error={errors.payment_account_number_confirm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_routing_number"
                            title="ACH Routing Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_routing_number}
                            error={errors.payment_routing_number}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_routing_number_confirm"
                            title="Confirm ACH Routing Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_routing_number_confirm}
                            error={errors.payment_routing_number_confirm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}

                {values?.has_escrow === "No" && (
                  <>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <label>Do you need an escrow agent?</label>
                      <div>
                        <label
                          className="form-check-label me-2"
                          htmlFor="has_escrow"
                        >
                          Yes
                        </label>
                        <input
                          className="form-check-input me-4"
                          label="Yes"
                          name="need_escrow_agent"
                          type="radio"
                          value="Yes"
                          onChange={handleChange}
                          checked={values?.need_escrow_agent === "Yes"}
                        />
                        <label
                          className="form-check-label me-2"
                          htmlFor="has_escrow"
                        >
                          No
                        </label>
                        <input
                          className="form-check-input "
                          label="No"
                          name="need_escrow_agent"
                          type="radio"
                          value="No"
                          onChange={handleChange}
                          checked={values?.need_escrow_agent === "No"}
                        />
                      </div>
                    </div>
                  </>
                )}

                {values?.need_escrow_agent === "Yes" &&
                  values?.has_escrow === "No" && (
                    <div className="mt-3">
                      <i className="fe fe-info text-warning me-1"></i>Great! One
                      of our agents will contact you at the end of your
                      onboarding to assist you.
                    </div>
                  )}

                {values?.has_escrow === "No" &&
                  values?.need_escrow_agent === "No" && (
                    <>
                      <h3 className="mt-3">Your Company Bank Details</h3>

                      <FormControl
                        type="text"
                        name="payment_bank_name"
                        title="Bank name"
                        required={true}
                        placeholder="e.g. Bank of America"
                        value={values.payment_bank_name}
                        error={errors.payment_bank_name}
                        onChange={handleChange}
                      />

                      <FormControl
                        type="text"
                        name="payment_account_name"
                        title="Account name"
                        required={true}
                        placeholder="e.g. ABC Inc"
                        value={values.payment_account_name}
                        error={errors.payment_account_name}
                        onChange={handleChange}
                      />

                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_account_number"
                            title="Account Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_account_number}
                            error={errors.payment_account_number}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_account_number_confirm"
                            title="Confirm Account Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_account_number_confirm}
                            error={errors.payment_account_number_confirm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_routing_number"
                            title="ACH Routing Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_routing_number}
                            error={errors.payment_routing_number}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="payment_routing_number_confirm"
                            title="Confirm ACH Routing Number"
                            required={true}
                            placeholder="e.g. 9837219812"
                            value={values.payment_routing_number_confirm}
                            error={errors.payment_routing_number_confirm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}

                <hr />
                <button className="btn btn-eq-primary" type="submit">
                  Save &amp; Continue
                </button>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardAccount;
