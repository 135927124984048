import { useToast } from "@chakra-ui/react";
import { useIssuer } from "@equidefi/portals/hooks/useIssuers";
import { useEffect, useState } from "react";
import { Link } from "react-router";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

import Spinner from "../../components/spinner";
import { getPaymentMethod } from "../../hooks/useStripe";

const BankingInfo = () => {
  const [isLoading, setLoading] = useState(true);
  const { data: user } = useCurrentUser();
  const id = Object.keys(user?.roles?.issuer ?? {})?.[0];
  const { data: issuer } = useIssuer(id);

  const [paymentMethod, setPaymentMethod] = useState();
  const toast = useToast();
  useEffect(() => {
    const getAPIData = async () => {
      const result = await getPaymentMethod(issuer.id);
      setPaymentMethod(result);
    };
    if (!issuer?.id) return;
    getAPIData()
      .then()
      .catch((error) => {
        if (error.response.status === 404) {
          setPaymentMethod(null);
        } else {
          const message = error?.response.data.errors.join(". ");
          toast({
            status: "error",
            description: `There was a problem loading your banking info: <br/><br/> ${message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [issuer]);

  if (isLoading) return <Spinner show={true} />;

  if (!paymentMethod) {
    return (
      <div className="card banking-info">
        <div className="row connect">
          <a href="/payment">Connect your bank to EquiDeFi</a>
        </div>
      </div>
    );
  }

  return (
    <div className="card banking-info">
      <div className="row">
        <div className="col-10 title">Banking Information</div>
        <div className="col-2 change-bank">
          <div className="change-button">
            <Link to="/payment" className="btn btn-eq-primary">
              Change
            </Link>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Routing Number</th>
              <th>Account Number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{paymentMethod.us_bank_account.bank_name}</td>
              <td>{paymentMethod.us_bank_account.routing_number}</td>
              <td>.......{paymentMethod.us_bank_account.last4}</td>
              <td>Active</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BankingInfo;
