import React, { useState } from "react";
import { Link, useParams } from "react-router";

import moment from "moment";
import swal from "sweetalert";

import
  {
    Button,
    ButtonGroup,
    Flex,
    IconButton,
    useToast
  } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import { AgreementUploadModal } from "../../../components/offerings/AgreementUploadModal";
import Spinner from "../../../components/spinner";
import { IconControl, deleteOfferingAgreement } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

const WizardAgreements = (props) => {
  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);
  const toast = useToast();
  const [modal, showModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [agreement, setAgreement] = useState({});

  const handleDelete = (agreement) => {
    swal({
      title: `Remove Agreement`,
      text: `Are you sure you want to remove '${agreement.name}'?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        deleteOfferingAgreement(offering.id, agreement.id).then(async () => {
          toast({
            status: "success",
            description: `${agreement.name} has been removed`,
          });
          await refetch();
          showSpinner(false);
        });
      }
    });
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <Flex justify="space-between">
        <h2>
          <IconControl
            placement="right"
            iconName="fe fe-info ms-2"
            inputTitle="Execution Documents"
            tooltipTitle="Please upload Word versions of only the agreements your investors will be executing. We will label these documents in DocuSign for signature by the appropriate parties"
          />
        </h2>
        <Button
          size="sm"
          colorScheme="gray"
          onClick={() => {
            showModal(true);
            setAgreement({});
          }}
        >
          Upload
        </Button>
      </Flex>
      <hr />

      <Spinner show={spinner}>
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th>Agreement Name</th>
              <th>Date Uploaded</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {offering?.agreements?.map((item) => (
              <tr key={item.id}>
                <td className="text-start align-baseline">
                  {item.name}{" "}
                  {item.template_id && (
                    <i
                      className="fe fe-file-text text-muted ms-3"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={`${item.template_id}`}
                    ></i>
                  )}
                </td>
                <td className="align-baseline">
                  {item.create_date && moment(item.create_date).format("ll")}
                </td>
                <td className="text-end align-baseline">
                  <ButtonGroup size="sm" colorScheme="gray">
                    <IconButton
                      as="a"
                      icon={<Icon.Download size="1em" />}
                      aria-label="Download Agreement"
                      href={item.agreement_url}
                      target="_blank"
                      rel="noreferrer"
                    />
                    <IconButton
                      icon={<Icon.Edit size="1em" />}
                      aria-label="Edit Agreement"
                      onClick={() => {
                        setAgreement(item);
                        showModal(true);
                      }}
                    />
                    <IconButton
                      icon={<Icon.Trash size="1em" />}
                      aria-label="Delete Agreement"
                      onClick={() => handleDelete(item)}
                    />
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <AgreementUploadModal
          agreement={agreement}
          offering={offering}
          onComplete={refetch}
          isOpen={modal}
          onClose={() => showModal(false)}
        />
      </Spinner>

      <hr />
      <Button as={Link} to={`/offering/${offering.id}/wizard/kyc`}>
        Save &amp; Continue
      </Button>
    </>
  );
};

export default WizardAgreements;
