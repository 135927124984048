import { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router";

import Spinner from "@/components/spinner";
import { AppLayout } from "@/layouts/AppLayout";
import { AuthLayout } from "@/layouts/AuthLayout";
import { PrivateRoute, PublicRoute } from "@equidefi/portals/components/Routes";

import { AdminLayout } from "@/layouts/admin";
import { PublicLayout } from "@/layouts/public";

import CompleteProfile from "./pages/auth/completeProfile";
import ForgotLegacy from "./pages/auth/forgot";
import LoginLegacy from "./pages/auth/login";
import MFALegacy from "./pages/auth/mfa";
import ResetLegacy from "./pages/auth/reset";

import InquiriesList from "./pages/admin/inquiries";

import OfferingListLegacy from "./pages/offering";
import OfferingCreate from "./pages/offering/create";
import OfferingOverview from "./pages/offering/overview";
import OfferingWizard from "./pages/offering/wizard";

import Investment from "./pages/offering/InvestmentOverview";
import OfferingInvestmentsLegacy from "./pages/offering/investors";

import BrokerListLegacy from "./pages/brokers";
import BrokerEditorLegacy from "./pages/brokers/editor";

import OfferingAgreementsLegacy from "./pages/offering/agreements";
import OfferingBrokers from "./pages/offering/brokers";
import BulkInvite from "./pages/offering/bulkInvite";
import OfferingContacts from "./pages/offering/contacts";
import OfferingDocuments from "./pages/offering/documents";
import OfferingAgreements from "./pages/offering/OfferingAgreements";
import OfferingSettings from "./pages/offering/settings";

import MyProfile from "./pages/auth/profile";
import InvoiceList from "./pages/invoices";
import { Invoice } from "./pages/invoices/Invoice";
import Payment from "./pages/invoices/Payment";
import Success from "./pages/invoices/Success";
import IssuerListLegacy from "./pages/issuer";
import IssuerEditor from "./pages/issuer/editor";
import IssuerOfferings from "./pages/issuer/offerings";
import IssuerTeam from "./pages/issuer/team";
import MissingPage from "./pages/MissingPage";

import ScrollToTop from "./components/ScrollToTop";
import AccreditationList from "./pages/accreditation";
import InvestorDocuments from "./pages/accreditation/InvestorDocuments";
import Roles from "./pages/issuer/roles";
import AcceptInvitation from "./pages/issuer/team/AcceptInvitation";
import Invitations from "./pages/issuer/team/Invitations";
import OfferingAdmin from "./pages/offering/Admin";
import OfferingTrancheLegacy from "./pages/offering/Tranche";
import OfferingTraunchesLegacy from "./pages/offering/Traunches";

const Forgot = lazy(() => import("@/areas/Auth/Forgot"));
const Login = lazy(() => import("@/areas/Auth/Login"));
const MFA = lazy(() => import("@/areas/Auth/MFA"));
const Reset = lazy(() => import("@/areas/Auth/Reset"));

const BrokerNew = lazy(() => import("@/areas/Brokers/BrokerNew"));
const BrokerEdit = lazy(() => import("@/areas/Brokers/BrokerEdit"));
const BrokerList = lazy(() => import("@/areas/Brokers/BrokerList"));

const OfferingAdminEdit = lazy(() =>
  import("@/areas/Offerings/OfferingAdminEdit")
);
const OfferingClosing = lazy(() => import("@/areas/Offerings/OfferingClosing"));
const OfferingList = lazy(() => import("@/areas/Offerings/OfferingList"));
const OfferingShow = lazy(() => import("@/areas/Offerings/OfferingShow"));
const OfferingEdit = lazy(() => import("@/areas/Offerings/OfferingEdit"));
const OfferingInvestments = lazy(() =>
  import("@/areas/Offerings/OfferingInvestments")
);
const OfferingTranche = lazy(() => import("@/areas/Offerings/OfferingTranche"));
const OfferingTranches = lazy(() =>
  import("@/areas/Offerings/OfferingTranches")
);

const InvestmentShow = lazy(() => import("@/areas/Investments/InvestmentShow"));
const InvestmentPayments = lazy(() =>
  import("@/areas/Investments/InvestmentPayments")
);
const InvestmentKyc = lazy(() => import("@/areas/Investments/InvestmentKyc"));
const InvestmentAgreements = lazy(() =>
  import("@/areas/Investments/InvestmentAgreements")
);
const InvestmentAccreditation = lazy(() =>
  import("@/areas/Investments/InvestmentAccreditation")
);
const InvestmentHistory = lazy(() =>
  import("@/areas/Investments/InvestmentHistory")
);

const Profile = lazy(() => import("@/areas/Profile/Profile"));

const IssuerList = lazy(() => import("@/areas/Issuers/IssuerList"));
const IssuerNew = lazy(() => import("@/areas/Issuers/IssuerNew"));
const IssuerEdit = lazy(() => import("@/areas/Issuers/IssuerEdit"));
const IssuerShow = lazy(() => import("@/areas/Issuers/IssuerShow"));

const LegacyRoutes = ({ isAdmin }) => {
  return (
    <Routes>
      <Route path="v2">
        <Route
          element={
            <PublicRoute fallbackUrl="/v2/offerings">
              <AuthLayout />
            </PublicRoute>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="mfa" element={<MFA />} />
          <Route path="reset/:id/:token" element={<Reset />} />
        </Route>

        <Route
          element={
            <PrivateRoute fallbackUrl="/v2/login">
              <AppLayout />
            </PrivateRoute>
          }
        >
          {/* Redirect old offering routes to new offerings (plural) routes */}
          <Route path="offering/*" element={<Navigate to="/v2/offerings" />} />
          <Route path="issuer/*" element={<Navigate to="/v2/issuers" />} />

          <Route path="offerings">
            <Route index element={<OfferingList />} />

            <Route path=":id">
              <Route index element={<OfferingShow />} />
              <Route path="admin" element={<OfferingAdminEdit />} />
              <Route path="edit" element={<OfferingEdit />} />

              <Route path="closing" element={<OfferingClosing />} />
              <Route path="tranches" element={<OfferingTranches />} />
              <Route path="tranches/:id" element={<OfferingTranche />} />

              <Route path="investments" element={<OfferingInvestments />} />
              <Route path="investments/:id">
                <Route index element={<InvestmentShow />} />
                <Route path="payments" element={<InvestmentPayments />} />
                <Route path="kyc" element={<InvestmentKyc />} />
                <Route path="agreements" element={<InvestmentAgreements />} />
                <Route
                  path="accreditation"
                  element={<InvestmentAccreditation />}
                />
                <Route path="history" element={<InvestmentHistory />} />
              </Route>
            </Route>
          </Route>

          <Route path="issuers">
            <Route index element={<IssuerList />} />
            <Route path="new" element={<IssuerNew />} />
            <Route path=":id" index element={<IssuerShow />} />
            <Route path=":id/edit" element={<IssuerEdit />} />
          </Route>

          <Route path="brokers">
            <Route index element={<BrokerList />} />
            <Route path="new" element={<BrokerNew />} />
            <Route path=":id" element={<BrokerEdit />} />
          </Route>

          <Route path="profile" element={<Profile />} />
        </Route>
      </Route>

      <Route
        element={
          <PublicRoute fallbackUrl="/offering">
            <PublicLayout />
          </PublicRoute>
        }
      >
        <Route index element={<LoginLegacy />} />
        <Route path="mfa" element={<MFALegacy />} />
        <Route path="forgot" element={<ForgotLegacy />} />
        <Route path="reset/:id/:token" element={<ResetLegacy />} />
        <Route
          path="accept-invite/issuer/:token"
          element={<AcceptInvitation />}
        />
        <Route path="complete-profile/:token" element={<CompleteProfile />} />
      </Route>
      <Route
        element={
          <PrivateRoute>
            <AdminLayout />
          </PrivateRoute>
        }
      >
        <Route path="brokers">
          <Route index element={<BrokerListLegacy />} />
          <Route path="create" element={<BrokerEditorLegacy />} />
          <Route path=":id/broker" element={<BrokerEditorLegacy />} />
        </Route>

        <Route path="issuer">
          <Route index element={<IssuerListLegacy />} />
          {isAdmin && <Route path="create" element={<IssuerEditor />} />}
          <Route path=":id/users" element={<IssuerTeam />} />
          <Route path=":id/offerings" element={<IssuerOfferings />} />
          <Route path=":id/invitations" element={<Invitations />} />
          {isAdmin && <Route path=":id/edit" element={<IssuerEditor />} />}
          {isAdmin && <Route path=":id/roles" element={<Roles />} />}
        </Route>

        <Route path="admin">
          <Route path="inquiries" element={<InquiriesList />} />
        </Route>

        <Route path="/invoices" element={<InvoiceList />} />
        <Route path="/accreditation" element={<AccreditationList />} />
        <Route path="/accreditation/:id" element={<InvestorDocuments />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment/success" element={<Success />} />
        <Route path="/invoice/:id" element={<Invoice />} />

        <Route path="offering">
          <Route index element={<OfferingListLegacy />} />
          <Route path="create" element={<OfferingCreate />} />

          <Route path=":id">
            <Route path="overview" element={<OfferingOverview />} />
            <Route path="edit" element={<OfferingCreate />} />
            <Route path="admin" element={<OfferingAdmin />} />
            <Route path="tranches">
              <Route index element={<OfferingTraunchesLegacy />} />
              <Route path=":trauncheId" element={<OfferingTrancheLegacy />} />
            </Route>
            <Route path="agreements" element={<OfferingAgreements />} />
            <Route path="wizard/*" element={<OfferingWizard />} />
            <Route path="investments" element={<OfferingInvestmentsLegacy />} />
            <Route
              path="investment/:investment_id/overview"
              element={<Investment />}
            />
            <Route path="contacts" element={<OfferingContacts />} />
            <Route path="brokers" element={<OfferingBrokers />} />
            <Route path="documents" element={<OfferingDocuments />} />
            <Route
              path="agreements_legacy"
              element={<OfferingAgreementsLegacy />}
            />
            <Route path="settings" element={<OfferingSettings />} />
            <Route path="bulk-invite" element={<BulkInvite />} />
          </Route>
        </Route>

        <Route path="profile" element={<MyProfile />} />
        <Route path="*" element={<MissingPage />} />
      </Route>
    </Routes>
  );
};

const AppRoutes = ({ isAdmin }) => {
  return (
    <BrowserRouter>
      {/* Uses a hook under the hook. Some nuance may be needed page-to-page */}
      <ScrollToTop />
      <Suspense fallback={<Spinner show />}>
        <LegacyRoutes isAdmin={isAdmin} />
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
