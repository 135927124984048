import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { useAuth } from "@equidefi/portals/hooks/useAuth";

const DEFAULT_MESSAGE = "You must be logged in to access this page.";

export const useRequireUser = ({ destination = "/", onMount = true } = {}) => {
  const { isLoggedIn, clearToken } = useAuth();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const clearSession = useCallback(() => {
    clearToken();

    dispatch({ type: "email", payload: "" });
  }, [dispatch]);

  const triggerLogout = useCallback(
    ({ type = "error", message = DEFAULT_MESSAGE } = {}) => {
      clearSession();

      toast({
        status: type,
        description: message,
      });

      navigate(destination);
    },
    [navigate, destination, clearSession]
  );

  useEffect(() => {
    if (onMount && !isLoggedIn) {
      triggerLogout();
    }
  }, []);

  return triggerLogout;
};
